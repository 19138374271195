import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/user-slice';
import '../../css/RequestSummay.css';

export const VipRequestSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routeChange = () => {
    navigate('/');
    dispatch(userActions.addUserUpload());
  };

  return (
    <div>
      <h2 className="successTitle">Congratulations</h2>
      <p className="finalText">
        Succes! Your VIP user will be sent a voucher via email from{' '}
        <span style={{ fontWeight: 'bold' }}>
          employee_appaccess@discoveryplus.com
        </span>
        .
      </p>
      <p className="finalText">
        Please contact us if you do not receive a voucher within 30 minutes.
      </p>
      <br></br>
      <button className="button" onClick={routeChange}>
        {' '}
        Exit{' '}
      </button>
    </div>
  );
};

import { CustomButton } from '../../components/CustomButton/CustomButton';
import { MdNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';

export type FormDisplay = {
  userEmail: string;
  legacyEmail: string;
  campaignMarket: string;
  offerProduct: string;
  whiteListingDomains: string[];
};

export type EmployeeInformationProps = {
  formDisplay: FormDisplay;
  handleSubmit: React.MouseEventHandler<HTMLButtonElement>;
  handleBack: React.MouseEventHandler<HTMLButtonElement>;
};

export const EmployeeInformation = ({
  formDisplay,
  handleSubmit,
  handleBack,
}: EmployeeInformationProps) => (
  <>
    <h2 className="employeeTitle">Review Your Info Then Submit</h2>
    <div className="text employeePageInformationsWrapper">
      <p>
        <div>Check your information below and confirm you selected the correct streaming service and location.</div>
        <div>You can only request 1 code per streaming service per year, so please review carefully.</div>
        <div>Note that voucher codes must be redeemed in the location selected and they cannot be redeemed until any current subscriptions expire.</div>
      </p>
        <p>
      If your information is correct, click the Submit button at the bottom right corner of the screen.
      </p>
      <br/>
      <p>
        <span style={{ fontWeight: 'bold' }}>Your Work Email:</span>{' '}
        <span className="employeePageInformations">
          {formDisplay.userEmail}
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Your Streaming Service:</span>{' '}
        <span className="employeePageInformations">
          {formDisplay?.offerProduct}
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Your Location:</span>{' '}
        <span className="employeePageInformations">
          {formDisplay?.campaignMarket}
        </span>
      </p>
    </div>
    <div className="employeeButtonWrapper">
      <CustomButton
        onClick={handleSubmit}
        text="Submit"
        MobileIcon={MdOutlineNavigateNext}
        extraClasses="employeeButtonContinue"
        iconContext={{ color: '#04006c' }}
      />
      <CustomButton
        onClick={handleBack}
        text="< Back"
        MobileIcon={MdNavigateBefore}
        extraClasses="employeeButtonBack"
      />
    </div>
  </>
);

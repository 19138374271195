import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  email: string;
  token: string;
}

const slice = createSlice({
  name: 'auth',
  initialState: {
    email: '',
    token: '',
  },
  reducers: {
    setToken: (
      state: AuthState,
      action: { payload: AuthState['token']; type: string },
    ) => {
      state.token = action.payload;
    },
    setUserData: (
      state: AuthState,
      action: { payload: AuthState; type: string },
    ) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
    },
  },
});

export const authReducers = slice.reducer;
export const authActions = slice.actions;

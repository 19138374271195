import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';
import { userActions } from '../../redux/slices/user-slice';
import { UserType, Vip } from '../../services/types';
import { addUser } from '../../services/userServices';
import { ErrorPage } from '../common/ErrorPage';
import { Loading } from '../common/Loading';
import { VipRequestSummary } from '../Vip/VipRequestSummary';
import './../../css/Vip.css';

export const VipPage = () => {
  const InitiaValues = {
    applicant: {
      email: '',
    },
    type: '' as UserType,
    recipient: {
      firstname: '',
      lastname: '',
      email: '',
      businessReason: '',
    },
    market: '',
    product: '',
  };

  const [formValues, setFormValues] = useState(InitiaValues);
  const [selectedOffer, setSelectedOffer] = useState('');
  const [emailApplicantError, setEmailApplicantError] = useState('');
  const [emailRecipientError, setEmailRecipientError] = useState('');
  const isLoading = useSelector<any, boolean>((state) => state.user.isLoading);
  const status = useSelector<any, string>((state) => state.user.status);
  let pageNumber = useSelector<any, number>((state) => state.user.pageNumber);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vipData: Vip = {
    applicant: {
      email: formValues.applicant.email,
    },
    type: selectedOffer as UserType,
    recipient: {
      firstname: formValues.recipient.firstname,
      lastname: formValues.recipient.lastname,
      email: formValues.recipient.email,
      businessReason: formValues.recipient.businessReason,
    },
    market: formValues.market,
    product: formValues.product,
  };

  const productList = [
    {
      id: 1,
      name: 'discoveryplus',
    },
    {
      id: 2,
      name: 'eurosport',
    },
  ];
  const emailValidation = (email: any) => {
    const emailApplicantError = 'Your email adress is invalid';
    const emailRecipientError = 'Your email adress is invalid';
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    if (!email) return false;

    const emailParts = email.split('@');
    const account = emailParts[0];
    const address = emailParts[1];
    if (account.length > 64) {
      if (email === formValues.applicant.email) {
        return setEmailApplicantError(emailApplicantError);
      } else if (email === formValues.recipient.email) {
        return setEmailRecipientError(emailRecipientError);
      }
    } else if (address.length > 255) {
      if (email === formValues.applicant.email) {
        return setEmailApplicantError(emailApplicantError);
      } else if (email === formValues.recipient.email) {
        return setEmailRecipientError(emailRecipientError);
      }
    }

    const domainParts = address.split('.');
    if (domainParts[0] !== 'discovery') {
      if (email === formValues.applicant.email)
        return setEmailApplicantError(
          'You are not eligible to request a voucher.',
        );
    }
    if (!regex.test(email)) {
      if (email === formValues.applicant.email) {
        return setEmailApplicantError(emailApplicantError);
      } else if (email === formValues.recipient.email) {
        return setEmailRecipientError(emailRecipientError);
      }
    }
    return true;
  };

  const routeChangeVip = () => {
    pageNumber--;
    navigate('/vip');
    setEmailApplicantError('');
    setEmailRecipientError('');
    dispatch(userActions.addUserUpload());
  };

  const routeChangeHome = () => {
    pageNumber = 1;
    navigate('/');
    setEmailApplicantError('');
    setEmailRecipientError('');
    dispatch(userActions.addUserUpload());
  };

  const handleChangePage2 = (e: any) => {
    if (!formValues.applicant.email) {
      alert({ message: 'Please enter your email address', type: 'warning' });
    } else if (
      !formValues.recipient.email &&
      formValues.recipient.email !== 'test@example.com'
    ) {
      alert({
        message: 'Please enter the recipient email address',
        type: 'warning',
      });
    } else if (
      !formValues.recipient.firstname &&
      formValues.recipient.firstname !== "Recipient's Firstname"
    ) {
      alert({
        message: 'Please enter the recipient firstname',
        type: 'warning',
      });
    } else if (
      !formValues.recipient.lastname &&
      formValues.recipient.lastname !== "Recipient's Lastname"
    ) {
      alert({
        message: 'Please enter the recipient lastname',
        type: 'warning',
      });
    } else if (
      !formValues.recipient.businessReason &&
      formValues.recipient.businessReason !== 'Reason'
    ) {
      alert({
        message: 'Please enter the reason of the request',
        type: 'warning',
      });
    } else if (
      emailValidation(formValues.applicant.email) &&
      emailValidation(formValues.recipient.email)
    ) {
      pageNumber++;
      dispatch(userActions.addUserPage2(formValues));
    } else {
      alert({ message: 'An error appear please retry.', type: 'warning' });
      e.preventDefault();
    }
  };

  const handleChangePage3 = (e: any) => {
    if (!selectedOffer) {
      alert({
        message: 'You must select a duration before proceeding.',
        type: 'warning',
      });
    } else if (!formValues.market && formValues.market !== 'Choose a Market') {
      alert({
        message: 'You must select a market before proceeding.',
        type: 'warning',
      });
    } else if (
      !formValues.product &&
      formValues.product !== 'Choose a Product'
    ) {
      alert({
        message: 'You must select a product before proceeding.',
        type: 'warning',
      });
    } else if (
      emailValidation(formValues.applicant.email) &&
      emailValidation(formValues.recipient.email)
    ) {
      pageNumber++;
      dispatch(userActions.addUserPage3(formValues));
    } else {
      alert({ message: 'An error appear please retry.', type: 'warning' });
      e.preventDefault();
    }
  };
  const submit = async (e: any) => {
    e.preventDefault();
    dispatch<any>(addUser(vipData));
  };
  //     return (
  //         <div className="middleVip">
  //             {isLoading === false && status === "Pending" && pageNumber === 2 ? (
  //                 <div>
  //                     <h2 className="employeeTitle">Product Section</h2>
  //                     <form>
  //                         <div className="columnLeft2">
  //                             <h2 className="section">Market</h2>
  //                             <Input
  //                                 className="formBox"
  //                                 type='select'
  //                                 defaultValue=""
  //                                 name="market"
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     market: e.target.value,
  //                                 })}
  //                                 required
  //                             >
  //                                 <option value="" key="-1" disabled>
  //                                     Choose a Market
  //                                 </option>
  //                                 {countryList.map((market) => (
  //                                     <option
  //                                         value={market.name}
  //                                         key={market.id}
  //                                     >
  //                                         {market.name}
  //                                     </option>
  //                                 ))}
  //                                 ;
  //                             </Input>
  //                             <br></br>
  //                             <span style={{ fontSize: "14px" }}>Please select which market you will be using discovery+ in.<br></br>
  //                                 For testing, please use a test voucher, as you can only request access for one market.<br></br>
  //                                 Please note you can only select a market that discovery+ is available in.</span>
  //                         </div>
  //                         <div className="columnMiddle">
  //                             <h2 className="section">Product</h2>
  //                             <Input
  //                                 className="formBox"
  //                                 type='select'
  //                                 defaultValue=""
  //                                 name="products"
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     product: e.target.value,
  //                                 })}
  //                                 required
  //                             >
  //                                 <option value="" key="-1" disabled>
  //                                     Choose a Product
  //                                 </option>
  //                                 {productList.map((product) => (
  //                                     <option
  //                                         value={product.name}
  //                                         key={product.id}
  //                                     >
  //                                         {product.name}
  //                                     </option>
  //                                 ))}
  //                                 ;
  //                             </Input>
  //                             <br></br>
  //                             <span style={{ fontSize: "14px" }}>"Please select which product the user should have access to."</span>
  //                         </div>
  //                         <div className="columnRight2">
  //                             <h2 className="section">Duration</h2>
  //                             <div className="boxChoice">
  //                                 <input
  //                                     type="radio"
  //                                     value="1 Month"
  //                                     name="type"
  //                                     checked={selectedOffer === "1 Month"}
  //                                     onChange={(e) => setSelectedOffer(e.target.value)}
  //                                 />
  //                                 <span style={{ marginLeft: '5%' }}>1 Month</span>
  //                                 <br></br>
  //                                 <br></br>
  //                                 <input
  //                                     type="radio"
  //                                     value="3 Month"
  //                                     name="type"
  //                                     checked={selectedOffer === "3 Month"}
  //                                     onChange={(e) => setSelectedOffer(e.target.value)}
  //                                 />
  //                                 <span style={{ marginLeft: '5%' }}>3 Month</span>
  //                                 <br></br>
  //                                 <br></br>
  //                                 <input
  //                                     type="radio"
  //                                     value="6 Month"
  //                                     name="type"
  //                                     checked={selectedOffer === "6 Month"}
  //                                     onChange={(e) => setSelectedOffer(e.target.value)}
  //                                 />
  //                                 <span style={{ marginLeft: '5%' }}>6 Month</span>
  //                                 <br></br>
  //                                 <br></br>
  //                                 <input
  //                                     type="radio"
  //                                     value="12 Month"
  //                                     name="type"
  //                                     checked={selectedOffer === "12 Month"}
  //                                     onChange={(e) => setSelectedOffer(e.target.value)}
  //                                 />
  //                                 <span style={{ marginLeft: '5%' }}>12 Month</span>
  //                             </div>
  //                             <br></br>
  //                             <br></br>
  //                             <span style={{ fontSize: "14px" }}>This voucher will type a one year free access to discovery+
  //                                 on the highest available product tier in your respective market.</span>
  //                         </div>
  //                     </form>
  //                     <Button className="button" onClick={(e) => handleChangePage3(e)}>Validate {">"}</Button>
  //                     <Button className="buttonBack" onClick={routeChangeVip}>{"<"} Back {" "}</Button>
  //                 </div>
  //             ) : isLoading === false && status === "Pending" && pageNumber === 3 ? (
  //                 <div>
  //                     <h2 className="employeeTitle">Request Summary</h2>
  //                     <h3 style={{ fontWeight: "bold", fontSize: "24px", textAlign: "center" }}>Please make sure your information are correct:</h3>
  //                     <br></br>
  //                     <div className="columnLeft1">
  //                         <h2 className="section">Request Section</h2>
  //                         <div className="textVip">
  //                             <p className="underSection">Recipient Data:</p>
  //                             <br></br>
  //                             <p><span style={{ fontWeight: "bold"}}>Your email adress :</span> {formValues.applicant.email}</p>
  //                             <br></br>
  //                             <br></br>
  //                             <p className="underSection">Applicant data:</p>
  //                             <br></br>
  //                             <p><span style={{ fontWeight: "bold" }}>Recipient Email Adress:</span> {formValues.recipient.email}</p>
  //                             <p><span style={{ fontWeight: "bold" }}>Recipient FirstName:</span> {formValues.recipient.firstname}</p>
  //                             <p><span style={{ fontWeight: "bold" }}>Recipient Lastname</span> {formValues.recipient.lastname}</p>
  //                             <p><span style={{ fontWeight: "bold" }}>Reason of the request :</span> {formValues.recipient.businessReason}</p>
  //                         </div>
  //                     </div>
  //                     <div className="columnRight1">
  //                         <h2 className="section">Product Section</h2>
  //                         <div className="textVip">
  //                             <p><span style={{ fontWeight: "bold" }}>Market:</span> {formValues.market}</p>
  //                             <p><span style={{ fontWeight: "bold" }}>Product:</span> {formValues.product}</p>
  //                             <p><span style={{ fontWeight: "bold" }}>Offer:</span> {selectedOffer}</p>
  //                             <Button className="button" onClick={submit}>Submit {">"}</Button>
  //                             <Button className="buttonBack" onClick={routeChangeVip}>{"<"} Back {" "}</Button>
  //                         </div>
  //                     </div>
  //                 </div>
  //             ) : isLoading && status === "void" ? (
  //                 < Loading />
  //             ) : isLoading === false && status === "Success" ? (
  //                 < VipRequestSummary />
  //             ) : isLoading === false && status === "Error" ? (
  //                 < ErrorPage />
  //             ) : (
  //                 <div>
  //                     <h2 className="vipTitle">VIP Request</h2>
  //                     <form>
  //                         <div className="columnLeft1">
  //                             <h2 className="section">Employee Applicant</h2>
  //                             <p className="fieldName">
  //                                 Corporate email address
  //                             </p>
  //                             <Input
  //                                 className="formBox"
  //                                 type="text"
  //                                 placeholder="example@discovery.com"
  //                                 name="email"
  //                                 value={formValues.applicant.email}
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     applicant: {
  //                                         ...formValues.applicant,
  //                                         email: e.target.value
  //                                     }
  //                                 })}
  //                                 required />
  //                             <span style={{ fontSize: "14px" }}>Enter your corporate email address</span>
  //                             <br></br>
  //                             <span style={{
  //                                 position: "relative",
  //                                 fontWeight: 'bold',
  //                                 color: 'red',
  //                             }}>{emailApplicantError}</span>
  //                         </div>
  //                         <div className="columnRight1">
  //                             <h2 className="section">Recipient informations</h2>
  //                             <p className="fieldName">
  //                                 Recipient firstname
  //                             </p>
  //                             <Input
  //                                 className="formBox"
  //                                 type='text'
  //                                 name="firstname"
  //                                 placeholder="Firstname"
  //                                 value={formValues.recipient.firstname}
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     recipient: {
  //                                         ...formValues.recipient,
  //                                         firstname: e.target.value
  //                                     }
  //                                 })}
  //                                 required
  //                             />
  //                             <span style={{ fontSize: "14px" }}>Enter the recipient's firstname</span>
  //                             <br></br>
  //                             <br></br>
  //                             <p className="fieldName">
  //                                 Recipient lastname
  //                             </p>
  //                             <Input
  //                                 className="formBox"
  //                                 type="text"
  //                                 placeholder="Lastname"
  //                                 name="lastname"
  //                                 value={formValues.recipient.lastname}
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     recipient: {
  //                                         ...formValues.recipient,
  //                                         lastname: e.target.value
  //                                     }
  //                                 })}
  //                                 required />
  //                             <span style={{ fontSize: "14px" }}>Enter the recipient's lastname</span>
  //                             <br></br>
  //                             <br></br>
  //                             <p className="fieldName">
  //                                 Recipient email
  //                             </p>
  //                             <Input
  //                                 className="formBox"
  //                                 type="text"
  //                                 placeholder="example@domain.com"
  //                                 name="email"
  //                                 value={formValues.recipient.email}
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     recipient: {
  //                                         ...formValues.recipient,
  //                                         email: e.target.value
  //                                     }
  //                                 })}
  //                                 required />
  //                             <span style={{ fontSize: "14px" }}>Enter the recipient's email address</span>
  //                             <span style={{
  //                                 position: "relative",
  //                                 fontWeight: 'bold',
  //                                 color: 'red',
  //                             }}>{emailRecipientError}</span>
  //                             <br></br>
  //                             <br></br>
  //                             <p className="fieldName">
  //                                 Business Reason
  //                             </p>
  //                             <Input
  //                                 className="formBoxBusiness"
  //                                 type="text"
  //                                 placeholder="Business reason"
  //                                 name="businessReason"
  //                                 value={formValues.recipient.businessReason}
  //                                 onChange={(e) => setFormValues({
  //                                     ...formValues,
  //                                     recipient: {
  //                                         ...formValues.recipient,
  //                                         businessReason: e.target.value
  //                                     }
  //                                 })}
  //                                 required />
  //                             <span style={{ fontSize: "14px" }}>Enter the business reason of the request</span>
  //                             <br></br>
  //                         </div>
  //                     </form>
  //                     <Button className="button" onClick={(e) => handleChangePage2(e)}>Validate {">"}</Button>
  //                     <Button className="buttonBack" onClick={routeChangeHome}>{"<"} Back {" "}</Button>
  //                 </div >
  //             )}
  //         </div >
  //     );
  // };
  return (
    <div className="loadingcontainer">
      <h2 className="vipTitle">Coming Soon.</h2>
      <br></br>
      <button className="button" onClick={routeChangeHome}>
        Exit {'>'}{' '}
      </button>
    </div>
  );
};

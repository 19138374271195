import '../../css/Loading.css';

export const Loading = () => {
  return (
    <div className="loadingcontainer">
      <div className="paragraph, center">
        Your request is being sent. Please wait.
      </div>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { Offer } from '../../services/types';

const slice = createSlice({
  name: 'offers',
  initialState: {
    status: 'idle',
    loaded: false,
    offerList: [] as Offer[],
  },

  reducers: {
    loadOffers: (state) => {
      state.status = 'loading';
    },
    setOffers: (state, action: { payload: Offer[] }) => {
      state.offerList = action.payload;
      state.status = 'loaded';
    },
  },
});

export const offersReducers = slice.reducer;
export const offersActions = slice.actions;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/user-slice';
import { MdHome } from 'react-icons/md';
import '../../css/ErrorPage.css';
import '../../css/RequestSummay.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { useOktaAuth } from '@okta/okta-react';
import { Campaign, Offer } from '../../services/types';

export const ErrorPage = () => {
  const error = useSelector<any, any>((state) => state.user.error);
  const offers = useSelector<any, Offer[]>((state) => state.offers.offerList);
  const campaigns = useSelector<any, Campaign[]>((state) => state.campaigns.campaigns);

  const { authState } = useOktaAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routeChange = () => {
    navigate('/');
    dispatch(userActions.addUserUpload());
  };

  const getError = (error: any) => {
    switch (error.errorCode) {
      case 403:
        return getOktaStatusOrCountryError();
      case 422:
        return getEmaildomainError();
      case 404:
        return getNoActiveOfferProductError();
      case 410:
        return geNoVoucherAvailableError(error);
      default:
        return getStandardError(error.message);
    }
  }

  const getStandardError = (message: any) => (
    <>
      <h2 className="errorTitle">Your request ended in Error.</h2>
      <p className="errorText">
        <span className="bold">Error message:</span> {message}
      </p>
      <p className="errorText">
        Please contact Customer Support at{' '}
        <a href="mailto:employee_appaccess@discoveryplus.com" className="bold">
          employee_appaccess@discoveryplus.com
        </a>
        .
      </p>
    </>
  );

  const getEmaildomainError = () => (
    <>
      <h2 className="errorTitle">There was a problem with your request.</h2>
      <p className="errorText">
        Your email domain is not eligible to receive a voucher. If you need access for work purposes, please submit an exception request at{' '}
        <a href="http://wbdemployeeaccess.com" target='_blank' className="bold">
          http://wbdemployeeaccess.com
        </a>
        .
      </p>
    </>
  );

  const geNoVoucherAvailableError = (error: any) => {
    const offerName = offers.find(x => x.id === error.additionalData?.offerId)?.name;
    const location = campaigns.find(x => x.id === error.additionalData?.campaignId)?.market;

    return (
      <>
        <h2 className="errorTitle">There was a problem with your request.</h2>
        <p className="errorText">
          {`There are no vouchers available for ${offerName} in ${location} (id: ${error.additionalData?.campaignId}).`}
        </p>
        <p className="errorText">
          For help, please reach out to{' '}
          <a href="mailto:wbdstreamingrequest@wbd.com " className="bold">
            wbdstreamingrequest@wbd.com
          </a>
          {' '}and include the error message received.
        </p>
      </>
    );
  }

  const getOktaStatusOrCountryError = () => (
    <>
      <h2 className="errorTitle">There was a problem with your request.</h2>
      <p className="errorText">
        {`You cannot request a voucher because your Okta employee status (${authState?.accessToken?.claims['Emp_Status']}) or your country is not eligible.`}
      </p>
      <p className="errorText">
        If you need access for work purposes, please submit an exception request at{' '}
        <a href="http://wbdemployeeaccess.com" target='_blank' className="bold">
          http://wbdemployeeaccess.com
        </a>
        .
      </p>
      {getStreamingServiceHelp()}
    </>
  );

  const getNoActiveOfferProductError = () => (
    <>
      <h2 className="errorTitle">There was a problem with your request.</h2>
      <p className="errorText">
        There is not an active streaming service offered in the location in which you reside.
      </p>
      {getStreamingServiceHelp()}
    </>
  );

  const getStreamingServiceHelp = () => (<>
    <p className="errorText">
      If you need help, please contact the email below based on the streaming service.
    </p>
    <p className="errorText">
      Max: {' '}
      <a href="mailto:maxemployeeaccess@wbd.com" className="bold">
        maxemployeeaccess@wbd.com
      </a>
    </p>
    <p className="errorText">
      discovery+: {' '}
      <a href="mailto:employee_appaccess@discoveryplus.com" className="bold">
        employee_appaccess@discoveryplus.com
      </a>
    </p>
    <p className="errorText">
      HBO Max: {' '}
      <a href="mailto:HBOMaxEmployeeAccess@warnermedia.com" className="bold">
        HBOMaxEmployeeAccess@warnermedia.com
      </a>
    </p>
  </>
  );

  return (
    <div>
      {getError(error)}
      <div className="errorButtonWrapper">
        <CustomButton
          onClick={routeChange}
          text="< Back"
          MobileIcon={MdHome}
          extraClasses="errorButtonBack"
        />
      </div>
    </div>
  );
};

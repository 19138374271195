import { Route, Routes, useNavigate } from 'react-router-dom';
import { EmployeePage } from './pages/Employee/Employee';
import { VipPage } from './pages/Vip/Vip';
import { Home } from './pages/common/Home';
import './css/App.css';
import './css/Common.css';
import { VipRequestSummary } from './pages/Vip/VipRequestSummary';
import { ErrorPage } from './pages/common/ErrorPage';
import { Header } from './pages/common/Header';
import { Footer } from './pages/common/Footer';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { RequiredAuth } from './components/SecureRoute/SecureRoute';
import { Loading } from './components/Loading/Loading';

export const Layout = (props: { children: JSX.Element }) => (
  <>
    <Header />
    {props.children}
    <div className="desktop">
      <Footer />
    </div>
  </>
);

const { REACT_APP_OKTA_CLIENT_ID, REACT_APP_OKTA_ISSUER } = process.env;

const oktaAuth = new OktaAuth({
  clientId: REACT_APP_OKTA_CLIENT_ID || '',
  redirectUri: window.location.origin + '/login/callback',
  issuer:
    REACT_APP_OKTA_ISSUER ||
    'https://{yourOktaDomain}.com/oauth2/{authServerId}',
  responseType: ['id_token', 'token'],
  scopes: ['openid', 'offline_access', 'email', 'Emp_Status', 'Country_Code' ],
});

export const App = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Layout>
      {
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route
              path="login/callback"
              element={<LoginCallback loadingElement={<Loading />} />}
            />
            <Route path="/" element={<RequiredAuth />}>
              <Route
                path=""
                element={
                  <>
                    <Home />
                    <div className="mobile">
                      <Footer />
                    </div>
                  </>
                }
              />
            </Route>
            <Route path="employee" element={<RequiredAuth />}>
              <Route path="" element={<EmployeePage />} />
            </Route>
            <Route path="vip" element={<RequiredAuth />}>
              <Route path="" element={<VipPage />} />
            </Route>
            <Route path="viprequestsummary" element={<RequiredAuth />}>
              <Route path="" element={<VipRequestSummary />} />
            </Route>
            <Route path="error" element={<ErrorPage />} />
          </Routes>
        </Security>
      }
    </Layout>
  );
};

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/user-slice';
import { MdHome } from 'react-icons/md';
import '../../css/RequestSummay.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';

export const EmployeeRequestSummary = ({
  emailSupport,
}: {
  emailSupport: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routeChange = () => {
    navigate('/');
    dispatch(userActions.addUserUpload());
  };

  return (
    <div>
      <h2 className="successTitleEmployee">Check Your Inbox</h2>
      <p className="successText">
        We’ve sent your voucher code to your work email address.
      </p>
      <p className="successText">
        Follow the instructions in the email to redeem your code and start
        streaming!
      </p>
      <br></br>
      <p className="successText">
        If you don’t see an email within 1 hour, contact us at Customer Support
        at{' '}
        <a href={`mailto:${emailSupport}`} className="bold">
          {emailSupport}
        </a>
        .
      </p>
      <br></br>
      <a href={`mailto:${emailSupport}`} className="mailto">
        Contact Us
      </a>
      <div className="errorButtonWrapper">
        <CustomButton
          onClick={routeChange}
          text="< Back"
          MobileIcon={MdHome}
          extraClasses="errorButtonBack"
        />
      </div>
    </div>
  );
};

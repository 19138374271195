export const Loading = () => (
  <div
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      WebkitTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <h4>Loading...</h4>
  </div>
);

import '../../css/Footer.css';

const year = new Date().getFullYear();

export const Footer = () => {
  return (
    <div className="bottom">
      <div className="bottomLeft" />
      <div className="bottomMiddle">
        <span className="bottomMiddleText">
          © {year} Warner Bros. Discovery, Inc. or its subsidiaries and
          affiliates. All trademarks are the property of their respective
          owners. All rights reserved.
        </span>
      </div>
      <div className="bottomRight" />
    </div>
  );
};

import { Navbar } from './NavBar';
import './../../css/Home.css';

export const Home = () => {
  return (
    <div className="middle">
      <p className="homeTitle">Global Employee Access Request</p>
      <p className="homeParagraph">Warner Bros. Discovery Internal Use Only </p>
      <p className="homeParagraph">
        To request streaming access, enter your{' '}
        <span className="mobile inline">
          <br />
        </span>
        Warner Bros. Discovery email address and your country of residence on
        the next page.
        <br />
        If your eligibility is confirmed, we’ll send you a 12-month voucher for
        the streaming service you select.
      </p>
      <Navbar />
    </div>
  );
};

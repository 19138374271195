import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../redux/slices/auth-slice';
import { TokenManagerInterface, AccessToken } from '@okta/okta-auth-js';

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const auth = useSelector<any, { token: string }>((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      if (authState && authState.isAuthenticated) {
        const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;
        const accessToken: AccessToken = (await tokenManager.get(
          'accessToken',
        )) as AccessToken;

        dispatch<any>(
          authActions.setUserData({
            email: accessToken.claims.sub,
            token: authState.accessToken?.accessToken as string,
          }),
        );
      }
    };

    load();
  }, [authState, oktaAuth]); // Update if authState changes

  if (
    !authState ||
    (authState && authState?.isAuthenticated && auth.token === '')
  ) {
    return <Loading />;
  }

  if (!authState?.isAuthenticated) {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin,
    );
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();

    return <Loading />;
  }

  return <Outlet />;
};

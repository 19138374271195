import Select, { SingleValue } from 'react-select';

export interface ChoiceItem {
    value: string;
    label: string;
}

export type ChoiceInputProps = {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onHandleChange?: (newValue: SingleValue<ChoiceItem>) => void;
    name: string;
    isDisabled: boolean;
    isClearable: boolean;
    isSearchable: boolean;
    options: ChoiceItem[];
    extraClasses?: string;
    defaultValue?: ChoiceItem;
    value: ChoiceItem;
    noOptionsMessage?: string
};

export const ChoiceInput = ({
    name,
    onClick,
    onHandleChange,
    isDisabled,
    isClearable,
    isSearchable,
    options,
    extraClasses,
    defaultValue,
    noOptionsMessage = "No options",
    value
}: ChoiceInputProps) => {
    return (
        <div onClick={onClick} >
            <Select
                onChange={onHandleChange}
                className={extraClasses}
                classNamePrefix="select"
                defaultValue={defaultValue}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isSearchable={isSearchable}
                noOptionsMessage={() => noOptionsMessage}
                name={name}
                options={options}
                value={value}
            />
        </div>
    );
};

import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/ConfigureStore';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();

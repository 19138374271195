import { createSlice } from '@reduxjs/toolkit';
import { Vip, Employee } from '../../services/types';

const slice = createSlice({
  name: 'user',
  initialState: {
    status: 'void',
    userList: [] as Vip[] | Employee[],
    error: null,
    isLoading: false,
    pageNumber: 1,
  },

  reducers: {
    addUserBegin: (state) => {
      state.isLoading = true;
      state.status = 'void';
      state.pageNumber = 1;
    },
    addUserError: (state, action) => {
      state.isLoading = false;
      state.status = 'Error';
      state.error = action.payload;
    },
    addUserPage2: (state, _action) => {
      state.isLoading = false;
      state.status = 'Pending';
      state.pageNumber = 2;
    },
    addUserPage3: (state, _action) => {
      state.isLoading = false;
      state.status = 'Pending';
      state.pageNumber = 3;
    },
    addUserSuccess: (state, action) => {
      state.isLoading = false;
      state.status = 'Success';
      state.userList.push(action.payload);
    },
    addUserUpload: (state) => {
      state.isLoading = false;
      state.status = 'void';
      state.pageNumber = 1;
    },
  },
});

export const userReducers = slice.reducer;
export const userActions = slice.actions;

import { userReducers } from './slices/user-slice';
import { configureStore } from '@reduxjs/toolkit';
import { campaignsReducers } from './slices/campaigns-slice';
import { marketsReducers } from './slices/markets-slice';
import { offersReducers } from './slices/offers-slice';
import { authReducers } from './slices/auth-slice';

const store = configureStore({
  reducer: {
    user: userReducers,
    campaigns: campaignsReducers,
    markets: marketsReducers,
    offers: offersReducers,
    auth: authReducers,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

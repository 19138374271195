import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './../../css/Navbar.css';

export const Navbar = () => {
  const navigate = useNavigate();
  // const items = [
  //     {
  //         id: 1,
  //         name: "For Myself",
  //         path: "employee",
  //     },
  //      {
  //          id: 2,
  //          name: "For Someone Else",
  //          path: "vip",
  //      },
  // ];

  // return (
  //     <div className="navBar">
  //         {items.map((item) => (
  //             <div key={item.id}>
  //                 <Button className="button-proceed" onClick={() => navigate('/' + item.path)}>{item.name}</Button>
  //             </div>
  //         ))}
  //     </div>
  // );

  const onClick = () => {
    navigate('/employee');
  };
  return (
    <div className="navbar">
      <Button className="button-proceed" onClick={onClick}>
        Get Started
      </Button>
    </div>
  );
};

import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons';

import buttonStyles from './Button.module.css';
import './../../css/Common.css';

const defaultIconContext: IconContext = {
  color: '#fff',
  size: '30px',
};

export type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  MobileIcon: React.FC;
  extraClasses?: string;
  iconContext?: IconContext;
};

export const CustomButton = ({
  onClick,
  text,
  MobileIcon,
  extraClasses,
  iconContext,
}: ButtonProps) => {
  return (
    <Button
      className={`${buttonStyles.button} ${extraClasses}`}
      onClick={onClick}
    >
      <span className="desktop">{text}</span>
      <span className="mobile">
        <IconContext.Provider value={{ ...defaultIconContext, ...iconContext }}>
          <MobileIcon />
        </IconContext.Provider>
      </span>
    </Button>
  );
};

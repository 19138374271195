import { createSlice } from '@reduxjs/toolkit';
import { Market } from '../../services/types';

const slice = createSlice({
  name: 'markets',
  initialState: {
    status: 'idle',
    markets: [] as Market[],
  },

  reducers: {
    loadMarkets: (state) => {
      state.status = 'loading';
    },
    setMarkets: (state, action: { payload: Market[] }) => {
      state.markets = action.payload;
      state.status = 'loaded';
    },
  },
});

export const marketsReducers = slice.reducer;
export const marketsActions = slice.actions;

import { createSlice } from '@reduxjs/toolkit';
import { Campaign } from '../../services/types';

const slice = createSlice({
  name: 'campaigns',
  initialState: {
    status: 'idle',
    campaigns: [] as Campaign[],
  },

  reducers: {
    loadCampaigns: (state) => {
      state.status = 'loading';
    },
    setCampaigns: (state, action: { payload: Campaign[] }) => {
      state.campaigns = action.payload;
      state.status = 'loaded';
    },
  },
});

export const campaignsReducers = slice.reducer;
export const campaignsActions = slice.actions;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Employee,
  UserType,
  Campaign,
  Offer,
  Status,
  Market,
} from '../../services/types';
import { Loading } from '../common/Loading';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/user-slice';
import { addUser } from '../../services/userServices';
import { EmployeeRequestSummary } from './EmployeeRequestSummary';
import { ErrorPage } from '../common/ErrorPage';
import { Input, Alert } from 'reactstrap';
import '../common/Loading';
import './../../css/Employee.css';
import { emailValidation } from '../common/helpers/email.helper';
import { getCampaigns } from '../../services/getCampaigns';
import { getOffers } from '../../services/getOffers';
import { getMarkets } from '../../services/getMarkets';
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip';
import { EmployeeInformation, FormDisplay } from './EmployeeInformation';
import { MdHome, MdOutlineNavigateNext } from 'react-icons/md';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { useOktaAuth } from '@okta/okta-react';
import { TokenManagerInterface, AccessToken } from '@okta/okta-auth-js';
import { ChoiceInput, ChoiceItem } from '../../components/Input/choice';
import { SingleValue } from 'react-select';

const STREAMING_TOOLTIP_TEXT =
  'Choose a streaming service with Warner Bros. Discovery.';
const EMAIL_TOOLTIP_TEXT =
  'The WBD email address tied to your OKTA account will auto-populate here. Details of your subscription will be sent to your current email address.';
const COUNTRY_TOOLTIP_TEXT = 'Choose your country of residence.';

interface MarketMapping {
  key: string;
  value: string;
}

export const EmployeePage = () => {
  const InitialValues = {
    userEmail: '',
    legacyEmail: '',
    campaignId: '',
    offerId: '',
  };

  const InitialDisplay: FormDisplay = {
    userEmail: '',
    legacyEmail: '',
    campaignMarket: '',
    offerProduct: '',
    whiteListingDomains: [] as string[],
  };

  const defaultMarketLocationLabel = 'Choose a location';
  const defaulProductLabel = 'Your Streaming service';
  const loadingLabel = 'Loading...';
  const loadingStatus = 'loading';
  const defaultProductChoiceItem = {
    value: '',
    label: defaulProductLabel,
  } as ChoiceItem;

  const { oktaAuth, authState } = useOktaAuth();
  const [emailError, setEmailError] = useState('' as string | JSX.Element);
  const [emailEmptyError, setEmailEmptyError] = useState('');
  const [marketEmptyError, setMarketEmptyError] = useState('');
  const [productEmptyError, setProductEmptyError] = useState('');
  const [voucherRequestAlert, setVoucherRequestAlert] = useState(false);
  const [formValues, setFormValues] = useState(InitialValues);
  const [formDisplay, setFormDisplay] = useState(InitialDisplay);
  const [marketsMapping, setMarketsMapping] = useState([] as MarketMapping[]);
  const [defaultMarketChoiceItem, setDefaultMarketChoiceItem] = useState({
    value: '',
    label: defaultMarketLocationLabel,
  } as ChoiceItem);
  const [marketDisplayValue, setMarketDisplayValue] = useState(
    defaultMarketChoiceItem,
  );
  const [productDisplayValue, setProductDisplayValue] = useState(
    defaultProductChoiceItem,
  );

  const employeeData: Employee = {
    type: 'employee' as UserType,
    userEmail: formValues.userEmail,
    legacyEmail: formValues.legacyEmail,
    campaignId: formValues.campaignId,
    offerId: formValues.offerId,
  };

  let pageNumber = useSelector<any, number>((state) => state.user.pageNumber);
  const isLoading = useSelector<any, boolean>((state) => state.user.isLoading);
  const status = useSelector<any, string>((state) => state.user.status);
  const campaigns = useSelector<
    { campaigns: { campaigns: Campaign[] } },
    Campaign[]
  >((state) => state.campaigns.campaigns);
  const campaignsStatus = useSelector<any, Status>(
    (state) => state.campaigns.status,
  );
  const [offer, setOffer] = useState<Offer>();
  const offerList = useSelector<{ offers: { offerList: Offer[] } }, Offer[]>(
    (state) => state.offers.offerList,
  );
  const markets = useSelector<{ markets: { markets: Market[] } }, Market[]>(
    (state) => state.markets.markets,
  );
  const offersStatus = useSelector<any, Status>((state) => state.offers.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultEmailSupport = 'employee_appaccess@discoveryplus.com';
  const selectedOffer = offerList.find((o) => o.id === formValues.offerId);

  useEffect(() => {
    setDefaultMarketChoiceItem({
      ...defaultMarketChoiceItem,
      label:
        campaignsStatus === loadingStatus
          ? loadingLabel
          : defaultMarketLocationLabel,
    });
  }, [campaignsStatus]);

  useEffect(() => {
    const load = async () => {
      const info = await oktaAuth.token.getUserInfo();
      const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;
      const accessToken: AccessToken = (await tokenManager.get(
        'accessToken',
      )) as AccessToken;

      const email = accessToken.claims.sub;
      const legacyEmail = info.email as string;

      if (formValues.userEmail !== email) {
        setFormValues({
          ...formValues,
          userEmail: email,
          legacyEmail,
        });
      }
    };

    load();
  }, [oktaAuth, formValues]);

  useEffect(() => {
    if (voucherRequestAlert) {
      setTimeout(() => {
        setVoucherRequestAlert(false);
      }, 15000);
    }
  }, [voucherRequestAlert]);

  useEffect(() => {
    if (offersStatus === 'idle') {

      dispatch<any>(getOffers('employee'));
    }
  }, [offersStatus, dispatch, authState]);

  useEffect(() => {
    if (offersStatus === 'loaded' && offerList.length === 0) {
      dispatch(
        userActions.addUserError({
          message: `You cannot request a voucher because your Okta employee status (${authState?.accessToken?.claims['Emp_Status']}) or your country is not eligible.`,
          errorCode: 404
        }),
      );
    }
  }, [offersStatus, dispatch, authState, offerList]);

  useEffect(() => {
    if (!offer && offerList.length == 1) setOffer(offerList[0]);
  }, [offerList, offer, dispatch]);

  useEffect(() => {
    if (offer)
      setFormValues({
        ...formValues,
        offerId: offer.id as string,
      });
  }, [offer, dispatch]);

  useEffect(() => {
    if (offerList.length == 1)
      setFormValues({
        ...formValues,
        offerId: offerList[0].id as string,
      });
  }, [offerList, dispatch]);

  useEffect(() => {
    if (campaigns.length == 1) {
      setFormValues({
        ...formValues,
        campaignId: campaigns[0].id as string,
      });
    }

    if (campaigns.length > 0) {
      const marketIds = campaigns.map((campaign) => campaign.marketId);

      const uniqueMarketIds: string[] = [];
      marketIds.forEach((id) => {
        if (!uniqueMarketIds.includes(id)) {
          uniqueMarketIds.push(id);
        }
      });

      dispatch<any>(getMarkets(uniqueMarketIds));
    }
  }, [campaigns]);

  useEffect(() => {
    if (markets && markets.length > 0) {
      const marketsMapping = [];

      for (const market of markets) {
        const campaign = campaigns.find(
          (campaign) => campaign.marketId === market.id,
        );
        if (market.allowedCountries && market.allowedCountries.length > 0) {
          for (const countryName of market.allowedCountries) {
            marketsMapping.push({
              key: (campaign?.id as string) + '_' + countryName,
              value: countryName,
            });
          }
        } else {
          marketsMapping.push({
            key: (campaign?.id as string) + '_' + campaign?.market,
            value: campaign?.market as string,
          });
        }
      }

      setMarketsMapping(marketsMapping);
    }
  }, [markets]);

  useEffect(() => {
    if (formValues.offerId !== '' && formValues.campaignId === '') {
      dispatch<any>(getCampaigns(formValues.offerId as string));
      setFormDisplay({
        ...formDisplay,
        whiteListingDomains: offerList.find((o) => o.id === formValues.offerId)
          ?.whiteListingDomains as string[],
      });
    }
  }, [formValues, dispatch]);

  useEffect(() => {
    const selectedMarketMapping = marketsMapping.find((item) =>
      item.key.startsWith(formValues.campaignId),
    );
    setMarketDisplayValue(
      selectedMarketMapping
        ? ({
          value: selectedMarketMapping.key,
          label: selectedMarketMapping.value,
        } as ChoiceItem)
        : defaultMarketChoiceItem,
    );
  }, [formValues]);

  useEffect(() => {
    const selectedProduct = offerList.find(
      (item) => item.id === formValues.offerId,
    );
    setProductDisplayValue(
      selectedProduct
        ? ({
          value: selectedProduct.id,
          label: selectedProduct.product,
        } as ChoiceItem)
        : defaultProductChoiceItem,
    );
  }, [offerList, formValues]);

  const routeChangeEmployee = () => {
    pageNumber--;
    navigate('/employee');
    setEmailError('');
    dispatch(userActions.addUserUpload());
  };

  const routeChangeHome = () => {
    pageNumber = 1;
    navigate('/');
    setFormValues(InitialValues);
    dispatch(userActions.addUserUpload());
  };

  const sortMarket = (markets: MarketMapping[]) => {
    return markets.slice(0).sort(function (a: MarketMapping, b: MarketMapping) {
      const x = a.value.toLowerCase();
      const y = b.value.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  const onClickCampaign = () => {
    if (formValues.offerId === '') {
      setProductEmptyError("Please select first 'Your streaming service’");
    }
  };

  const onChange = (field: string, value: string) => {
    setMarketEmptyError('');
    setProductEmptyError('');
    setEmailEmptyError('');
    const changedItem: any = { ...formValues };
    if (field === 'offerId') {
      changedItem.campaignId = '';
      setMarketsMapping([]);
    }
    changedItem[field] = value;
    setFormValues(changedItem);
  };

  const handleChange = () => {
    if (!formValues.userEmail) {
      setEmailEmptyError('Please enter your email address');
      setProductEmptyError('');
    } else if (!formValues.offerId) {
      setProductEmptyError('Please select your streaming service.');
    } else if (!formValues.campaignId) {
      setMarketEmptyError('Please choose a market');
    } else {
      const validation = emailValidation(
        formValues.userEmail,
        formDisplay.whiteListingDomains ?? [],
      );
      if (validation) {
        setEmailEmptyError('');
        setProductEmptyError('');
        setMarketEmptyError('');
        setEmailError(validation);
      } else {
        setVoucherRequestAlert(false);
        dispatch(userActions.addUserPage2(formValues));
        setFormDisplay({
          ...formDisplay,
          userEmail: formValues.userEmail,
          campaignMarket: campaigns.find(
            (c) => c.id === formValues.campaignId.split('_')[0],
          )?.market as string,
          offerProduct: offerList.find((o) => o.id === formValues.offerId)
            ?.product as string,
        });
      }
    }
  };

  const submit = () => {
    const voucherRequestKey = `voucherRequest-${JSON.stringify(formValues)}`;
    const voucherRequest = localStorage.getItem(voucherRequestKey);
    const now = new Date().getTime();

    // if (voucherRequest && parseInt(voucherRequest) > now) {
    //   pageNumber = 1;
    //   dispatch(userActions.addUserUpload());
    //   setVoucherRequestAlert(true);
    //   return;
    // }

    const timeRestriction = new Date();
    timeRestriction.setHours(timeRestriction.getHours() + 1);
    localStorage.setItem(
      voucherRequestKey,
      timeRestriction.getTime().toString(),
    );

    employeeData.campaignId = employeeData.campaignId.split('_')[0];

    dispatch<any>(addUser(employeeData));
  };

  const onProductChange = (item: SingleValue<ChoiceItem>) => {
    onChange('offerId', item?.value || '');
  };

  const onMarketChange = (item: SingleValue<ChoiceItem>) => {
    onChange('campaignId', item?.value || '');
  };

  return (
    <div className="employeePage">
      {voucherRequestAlert && (
        <Alert color="danger">
          You already asked for a voucher less than an hour ago. Please try
          again later. Thank you.
        </Alert>
      )}
      {isLoading === false && status === 'Pending' && pageNumber === 2 ? (
        <EmployeeInformation
          formDisplay={formDisplay}
          handleSubmit={submit}
          handleBack={routeChangeEmployee}
        />
      ) : isLoading && status === 'void' ? (
        <Loading />
      ) : isLoading === false && status === 'Success' ? (
        <EmployeeRequestSummary
          emailSupport={
            selectedOffer?.emailSupport
              ? selectedOffer?.emailSupport
              : defaultEmailSupport
          }
        />
      ) : isLoading === false && status === 'Error' ? (
        <ErrorPage />
      ) : (
        <>
          <h2 className="employeeTitle">Tell Us About You</h2>
          <p className="explanations">
            Enter your information below to receive a voucher code for the
            selected streaming service. Your voucher code will only be
            redeemable in the location you request.
            <br />
            You can request one voucher per streaming service, per year.
            <br />
            Not all streaming services may be available in your country.
          </p>
          <br></br>
          <p className="explanations">*indicates a required field.</p>
          <br></br>
          <br></br>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="fieldWrapper">
              <div className="employeePageColumn">
                <p className="fieldName">
                  Your Work Email *
                  <span className="employeeTooltip">
                    <InfoTooltip text={EMAIL_TOOLTIP_TEXT} />
                  </span>
                </p>
                <Input
                  className="formBox"
                  type="text"
                  placeholder="Your.Name@wbd.com"
                  name="email"
                  value={formValues.userEmail}
                  readOnly={true}
                  onChange={(e) => onChange('userEmail', e.target.value)}
                  required
                />
                <span className="info">{EMAIL_TOOLTIP_TEXT}</span>
                <span className="error" >{emailError}</span>
                <span className="error">{emailEmptyError}</span>
              </div>
              <div className="employeePageColumn">
                <p className="fieldName">
                  Your Streaming Service *
                  <span className="employeeTooltip">
                    <InfoTooltip text={STREAMING_TOOLTIP_TEXT} />
                  </span>
                </p>
                <ChoiceInput
                  name={'product'}
                  options={offerList.map((item) => {
                    return {
                      value: item.id,
                      label: item.product,
                    } as ChoiceItem;
                  })}
                  onHandleChange={onProductChange}
                  isClearable={false}
                  value={productDisplayValue}
                  extraClasses={'employee-select'}
                  isSearchable={false}
                  isDisabled={false}
                  key={'productKey'}
                  noOptionsMessage={defaultProductChoiceItem.label}
                />
                <span className="info">{STREAMING_TOOLTIP_TEXT}</span>
                <span className="error">{productEmptyError}</span>
              </div>
              <div className="employeePageColumn">
                <p className="fieldName">
                  Your Location *
                  <span className="employeeTooltip">
                    <InfoTooltip text={COUNTRY_TOOLTIP_TEXT} />
                  </span>
                </p>
                <ChoiceInput
                  name={'market'}
                  options={sortMarket(marketsMapping).map((item) => {
                    return { value: item.key, label: item.value } as ChoiceItem;
                  })}
                  onClick={onClickCampaign}
                  onHandleChange={onMarketChange}
                  isClearable={false}
                  value={marketDisplayValue}
                  extraClasses={'employee-select'}
                  isSearchable={true}
                  isDisabled={false}
                  key={'marketKey'}
                  noOptionsMessage={defaultMarketChoiceItem.label}
                />
                <span className="info">{COUNTRY_TOOLTIP_TEXT}</span>
                <span className="error">{marketEmptyError}</span>
              </div>
            </div>
            <div className="employeeButtonWrapper">
              <CustomButton
                onClick={handleChange}
                text="Continue >"
                MobileIcon={MdOutlineNavigateNext}
                extraClasses="employeeButtonContinue"
                iconContext={{ color: '#04006c' }}
              />
              <CustomButton
                onClick={routeChangeHome}
                text="< Back"
                MobileIcon={MdHome}
                extraClasses="employeeButtonBack"
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

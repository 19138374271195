import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { IconContext } from 'react-icons';
import { MdInfoOutline } from 'react-icons/md';

type InfoTooltipProps = {
  text: string;
  placement?: Placement;
  iconContext?: IconContext;
};

const defaultProps: Required<Omit<InfoTooltipProps, 'text'>> = {
  placement: 'top',
  iconContext: {
    color: '#04006c',
    size: '15px',
  },
};

export const InfoTooltip = ({
  placement,
  text,
  iconContext,
}: InfoTooltipProps) => {
  return (
    <OverlayTrigger
      placement={placement ?? defaultProps.placement}
      overlay={
        <Tooltip id={`tooltip-${placement ?? defaultProps.placement}`}>
          {text}
        </Tooltip>
      }
    >
      <span>
        <IconContext.Provider value={iconContext ?? defaultProps.iconContext}>
          <MdInfoOutline />
        </IconContext.Provider>
      </span>
    </OverlayTrigger>
  );
};
